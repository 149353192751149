import { css } from '@emotion/css';
import { m, mobileMediaQuery, smallMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const blockWrapperCss = css`
  .sdsm-block > div {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
  Since this is custom component
  Page does not pass skipBreakTemplate, 2 breaks are rendered
  these styles are to prevent break from appearing
  */
  + .sdsm-break-top-straight {
    display: none;
  }

  + .sdsm-break-top-straight + .sdsm-break-bottom-straight {
    display: none;
  }

  /* prevent block expanding when toggling the checkbox */
  .sdsm-block {
    background-position-y: center;
    background-size: cover;
    /** accomodate box shadows */
    overflow: visible;
  }
`;

export const blockMarginBottonCss = css`
  margin-bottom: ${m('--spacing-l')};
`;

export const contentResetPaddingCss = css`
  &.ar-download-from-container .sdsm-block {
    padding-top: 0;
  }
`;

export const contentCss = css`
  /*
  same Content - Layout Dense, not a constant
  packages/snap-design-system-marketing/src/components/Content/Content.styled.ts#L449
  */
  padding: 26px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${m('--spacing-m')};
  .ar-dowload-form-header {
    margin-bottom: ${m('--spacing-xl')};
  }
`;

export const contentHeightBlockDenseCss = css`
  padding-top: ${m('--spacing-xxl')};
  padding-bottom: ${m('--spacing-xxl')};
  min-height: 480px;

  ${mobileMediaQuery} {
    min-height: min(480px, 80vh);
  }
`;

export const contentHeightBlockSpaciousCss = css`
  padding-top: ${m('--spacing-xxl')};
  padding-bottom: ${m('--spacing-xxl')};
  min-height: 680px;

  ${mobileMediaQuery} {
    min-height: min(680px, 80vh);
  }
`;

export const logoCss = css`
  padding-bottom: ${m('--spacing-l')};
`;

export const headerCss = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const headingCss = css`
  ${mobileMediaQuery} {
    text-align: center;
  }
`;

export const formCss = css`
  align-items: flex-start;
  margin: 0;
`;

export const formRowCss = css`
  display: flex;
  align-items: center;
  gap: ${m('--spacing-m')};

  ${smallMobileMediaQuery} {
    flex-direction: column;
  }
`;

export const formRowPlatformCss = css`
  .sdsm-form-field {
    margin: 0;
    flex-basis: auto;
    flex-grow: 0;
    width: auto;
  }

  select {
    padding-right: 80px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    border-radius: 500px;
    background: ${m('--form-input-bg-color')};

    /*
    expanding orginal select styles
    packages/snap-design-system-marketing/src/components/Form/styles.ts#L127
    */
    &:-ms-expand {
      display: none;
    }
  }
`;

export const formFieldsCss = css`
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};
`;

export const selectHeightLargeCss = css`
  select {
    height: 48px;
  }
`;

export const gapLargeCss = css`
  gap: ${m('--spacing-l')};
`;

export const hiddenCss = css`
  opacity: 0;
  visibility: hidden;
`;

export const alignRightCss = css`
  align-items: flex-end;
`;

export const alignCenterCss = css`
  align-items: center;
`;

export const rowAlignRightCss = css`
  justify-content: flex-end;
`;

export const rowAlignCenterCss = css`
  justify-content: center;
`;

export const textAlignRightCss = css`
  text-align: right;
`;

export const textAlignCenterCss = css`
  text-align: center;
`;
