import { Spinner } from '@snapchat/snap-design-system-marketing';
import Cookies from 'js-cookie';
import type { FC } from 'react';
import { useEffect } from 'react';

import type { LsDownloadsMap } from '../../helpers';
import { downloadsCookieKey, serializeVersionAndPlatform } from '../../helpers';
import { areQueryParamsValid } from '../../helpers/areQueryParamsValid';
import type { PlatformString } from '../../server';
import { spinnerContainerCss } from './ArDownloadIdCaptureBlock.styles';

const lensStudioUrl = 'http://127.0.0.1';
const lensStudioPath = '/auth';

export const ArDownloadIdCaptureBlock: FC = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const version = searchParams.get('version');
    const platform = searchParams.get('platform') as PlatformString | null;
    const lensStudioPort = searchParams.get('ls_port');

    if (version && platform && areQueryParamsValid(version, platform, lensStudioPort)) {
      // || operator instead of ?? to account for when map serialized to ""
      const lsDownloadsMap = (Cookies.getJSON(downloadsCookieKey) as LsDownloadsMap) || {};
      const downloadId = lsDownloadsMap[serializeVersionAndPlatform(version, platform)] ?? '';

      window.location.assign(
        `${lensStudioUrl}:${lensStudioPort}${lensStudioPath}?download_id=${downloadId}`
      );
    } else if (Number.isInteger(Number.parseInt(lensStudioPort ?? ''))) {
      window.location.assign(`${lensStudioUrl}:${lensStudioPort}${lensStudioPath}`);
    }
  }, []);

  return (
    <div className={spinnerContainerCss}>
      <Spinner />
    </div>
  );
};
